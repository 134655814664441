<template>
  <!-- eslint-disable -->
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light text-center">
              <h1>Reset Password</h1>
            </div>

            <!-- <div class="subtitle-1 font-weight-light">
              <h3>  Complete your profile</h3>
            </div>-->
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <span>
                    <h3>Please Enter the Code You have recieved</h3>
                  </span>
                </v-col>

                <v-col cols="12" md="5">
                  <v-text-field v-model="userName" class="purple-input" label="Code" />
                </v-col>
              </v-row>
              <v-row>
                  <v-col cols="12" md="6">
                  <span>
                    <h3>Please Enter Your New Password</h3>
                  </span>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field v-model="emailAddress" label="new password" type="password" class="purple-input" />
                </v-col>
              </v-row>
              <v-row>

                <v-col cols="12" class="text-right">
                  <v-btn color="success" class="mr-0" @click="submitFn">submit</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <!-- <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          class="v-card-profile"
          avatar="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg"
        >
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              CEO / CO-FOUNDER
            </h6>

            <h4 class="display-2 font-weight-light mb-3 black--text">
              Alec Thompson
            </h4>

            <p class="font-weight-light grey--text">
              Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
            </p>

            <v-btn
              color="success"
              rounded
              class="mr-0"
            >
              Follow
            </v-btn>
          </v-card-text>
      </base-material-card>-->
      <!-- </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
export default {
  name: "Register",
  data() {
    return {
      userName: null,
      emailAddress: null,
      password: null
    };
  },
  methods: {
    submitFn() {
        this.$router.push('/')
    }
  }
};
</script>
